import Autocomplete from 'stimulus-autocomplete'

export class ExtendedAutocomplete extends Autocomplete {
  onArrowDownKeydown = (event: Event) => {
    if (this.resultsShown) {
      // Due to JS class visibility, I have to copy this implementation
      // rather than just call the superclass method:
      // https://github.com/afcapel/stimulus-autocomplete/blob/bdb1753/src/autocomplete.js#L90-L94
      const item = this.sibling(true)
      if (item) this.select(item)
      event.preventDefault()
      return
    }
    this.revealResults(event)
  }

  revealResults(event: Event) {
    const query = this.inputTarget.value.trim()
    this.fetchResults(query)
    event.preventDefault()
  }
}
