import { Controller } from '@hotwired/stimulus'

export class QuickAnswerController extends Controller {
  static targets = ['radio']

  declare readonly radioTargets: HTMLInputElement[]

  quickAnswerRadios() {
    this.radioTargets.forEach((radio) => {
      radio.checked = true
    })
  }
}
