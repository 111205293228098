import { Controller } from '@hotwired/stimulus'
import { getFuzzyLocalTimeFromPoint } from '@mapbox/timespace'
import mapboxgl from 'mapbox-gl'

import { getMbPub, getMbST } from '../utils'
import { HTMLElementEvent } from '../types'
import { MapboxClient } from '../clients/mapbox_client'
import { MapboxRetrievedAddress } from '../models'

export class Mapbox extends Controller {
  static targets = [
    'addMapMarkerButton',
    'city',
    'county',
    'country',
    'latlong',
    'lineOne',
    'map',
    'postalCode',
    'stateOrProvince',
    'addressLatlong',
    'timeZone',
  ]
  declare readonly addMapMarkerButtonTarget: HTMLButtonElement
  declare readonly hasAddMapMarkerButtonTarget: boolean
  declare readonly cityTarget: HTMLInputElement
  declare readonly hasCityTarget: boolean
  declare readonly countyTarget: HTMLInputElement
  declare readonly hasCountyTarget: boolean
  declare readonly countryTarget: HTMLSelectElement
  declare readonly hasCountryTarget: boolean
  declare readonly latlongTarget: HTMLInputElement
  declare readonly hasLatlongTarget: boolean
  declare readonly lineOneTarget: HTMLInputElement
  declare readonly hasLineOneTarget: boolean
  declare readonly mapTarget: HTMLInputElement
  declare readonly hasMapTarget: boolean
  declare readonly postalCodeTarget: HTMLInputElement
  declare readonly hasPostalCodeTarget: boolean
  declare readonly stateOrProvinceTarget: HTMLSelectElement
  declare readonly hasStateOrProvinceTarget: boolean
  declare readonly addressLatlongTarget: HTMLInputElement
  declare readonly hasAddressLatlongTarget: boolean
  declare readonly timeZoneTarget: HTMLSelectElement
  declare readonly hasTimeZoneTarget: boolean

  // Geographical center of the contiguous United States
  DEFAULT_LATITUDE = 39.8282
  DEFAULT_LONGITUDE = -98.5796

  DEFAULT_FLY_TO_ZOOM = 18
  DEFAULT_INITIAL_ZOOM = 2

  declare accessToken: string
  declare currentMarkers: mapboxgl.Marker[]
  declare map: mapboxgl.Map | undefined
  declare sessionToken: string

  connect() {
    this.setTokens()
    this.initMap()
  }

  async retrieve(event: HTMLElementEvent<HTMLElement>) {
    const client = new MapboxClient(this.accessToken, this.sessionToken)
    const id = event.currentTarget.dataset.mapboxId

    try {
      const address = await client.retrieveAddress(id)

      this.setAddressFields(address)
      this.addLocationMarker(address)
    } catch (e) {
      alert(e)
    }
  }

  initMap(): void {
    this.currentMarkers = []

    if (this.hasMapTarget) {
      this.map = new mapboxgl.Map({
        attributionControl: false,
        center: [this.DEFAULT_LONGITUDE, this.DEFAULT_LATITUDE],
        container: this.mapTarget.id,
        testMode: this.testMode,
        zoom: this.DEFAULT_INITIAL_ZOOM,
      })

      this.displayAddMapMarkerButton()
    }
  }

  displayAddMapMarkerButton() {
    this.map.on('style.load', () => {
      const button = this.addMapMarkerButtonTarget
      button.classList.toggle('visible')
      button.ariaHidden = 'false'
    })
  }

  addLocationMarker(address: MapboxRetrievedAddress) {
    this.clearExistingMarkers()
    this.addMarker(address.latitude, address.longitude)
  }

  addMarker(latitude: number, longitude: number) {
    const marker = new mapboxgl.Marker()
      .setLngLat([longitude, latitude])
      .addTo(this.map)

    this.currentMarkers.push(marker)

    this.flyToPoint(latitude, longitude)
  }

  clearExistingMarkers() {
    this.currentMarkers.forEach((marker) => {
      marker.remove()
    })
  }

  flyToPoint(latitude: number, longitude: number, zoom?: number) {
    this.map.flyTo({
      center: [longitude, latitude],
      zoom: zoom || this.DEFAULT_FLY_TO_ZOOM,
      essential: true,
    })
  }

  setTokens(): void {
    this.accessToken = getMbPub()
    this.sessionToken = getMbST()

    mapboxgl.accessToken = this.accessToken
  }

  setAddressFields(address: MapboxRetrievedAddress) {
    this.setAddressLineOneValue(address)
    this.setAddressCityValue(address)
    this.setAddressCountyValue(address)
    this.setAddressStateOrProvinceValue(address)
    this.setAddressPostalCodeValue(address)
    this.setAddressCountryValue(address)
    this.setAddressLatlongValue(address)
    this.setLocationLatlongValue(address)
    this.setTimeZoneValue(address)
  }

  setAddressLineOneValue(address: MapboxRetrievedAddress) {
    if (address && this.hasLineOneTarget) {
      this.lineOneTarget.value = address.lineOne
    }
  }

  setAddressCityValue(address: MapboxRetrievedAddress) {
    if (address && this.hasCityTarget) {
      this.cityTarget.value = address.city
    }
  }

  setAddressCountyValue(address: MapboxRetrievedAddress) {
    if (this.hasCountyTarget) {
      this.countyTarget.value = address.county
    }
  }

  setAddressStateOrProvinceValue(address: MapboxRetrievedAddress) {
    if (this.hasStateOrProvinceTarget) {
      this.stateOrProvinceTarget.value = address.stateOrProvince
    }
  }

  setAddressPostalCodeValue(address: MapboxRetrievedAddress) {
    if (this.hasPostalCodeTarget) {
      this.postalCodeTarget.value = address.postalCode
    }
  }

  setAddressCountryValue(address: MapboxRetrievedAddress) {
    if (this.hasCountryTarget) {
      this.countryTarget.value = address.country
    }
  }

  setAddressLatlongValue(address: MapboxRetrievedAddress) {
    if (this.hasAddressLatlongTarget) {
      this.addressLatlongTarget.value = address.latlong
    }
  }

  setLocationLatlongValue(address: MapboxRetrievedAddress) {
    if (this.hasLatlongTarget) {
      this.latlongTarget.value = address.latlong
    }
  }

  setTimeZoneValue(address: MapboxRetrievedAddress) {
    if (this.hasTimeZoneTarget) {
      const timeZone = getFuzzyLocalTimeFromPoint(Date.now(), [
        address.coordinates.longitude,
        address.coordinates.latitude,
      ])

      this.timeZoneTarget.value = timeZone._z.name
    }
  }

  get testMode() {
    return this.accessToken === ''
  }
}
