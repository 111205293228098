import { Controller } from '@hotwired/stimulus'

import { HTMLElementEvent } from '../types'

export class Dropdown extends Controller {
  static targets = ['button', 'content', 'wrapper']

  declare readonly buttonTarget: HTMLElement
  declare readonly contentTarget: HTMLElement
  declare readonly wrapperTarget: HTMLElement

  toggle() {
    const isExpanded = !this.contentTarget.classList.contains('hidden')

    if (isExpanded) {
      this.close()
    } else {
      this.expand()
    }
  }

  close() {
    this.contentTarget.classList.add('hidden')
    this.buttonTarget.ariaExpanded = 'false'
  }

  expand() {
    this.contentTarget.classList.remove('hidden')
    this.buttonTarget.ariaExpanded = 'true'
  }

  globalClose(e: HTMLElementEvent<HTMLElement>) {
    if (this.wrapperTarget.contains(e.target)) return
    e.stopPropagation()
    this.close()
  }
}
