export function getMbPub(): string {
  const metaTag = document.head.querySelector(
    'meta[name="mb_pub"]',
  ) as HTMLMetaElement

  return metaTag.content
}

export function getMbST(): string {
  const metaTag = document.head.querySelector(
    'meta[name="mb_st"]',
  ) as HTMLMetaElement

  return metaTag.content
}
