import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

import { HTMLElementEvent } from '../types'

export class MultiSelectFiltersController extends Controller {
  static targets = ['select', 'hidden', 'form']

  declare hiddenTarget: HTMLInputElement
  declare hasHiddenTarget: boolean
  declare hiddenTargets: HTMLInputElement[]
  declare hasHiddenTargets: boolean
  declare formTarget: HTMLFormElement
  declare hasFormTarget: boolean
  declare selectTarget: HTMLSelectElement
  declare hasSelectTarget: boolean
  declare selectTargets: HTMLSelectElement[]
  declare hasSelectTargets: boolean

  declare pendingTimeout: NodeJS.Timeout | undefined

  disconnect() {
    if (this.pendingTimeout) {
      clearTimeout(this.pendingTimeout)
    }
  }

  connect() {
    this.setSelectedValues()
  }

  handleOnChange(e: HTMLElementEvent<HTMLSelectElement>) {
    const values = []
    for (let child of e.target.children) {
      if ((child as HTMLOptionElement).selected) {
        values.push(child.getAttribute('value'))
      }
    }

    this.hiddenTargets.forEach((target) => {
      if (target.id === e.target.id) {
        target.value = values.join(',')
      }
    })

    this.submitDebouncedForm()
  }

  handleSubmit(e: HTMLElementEvent<HTMLFormElement>) {
    const baseUrl = this.formTarget.getAttribute('action')
    const formData = new FormData(e.target)
    const searchParams = new URLSearchParams()

    for (const [key, value] of formData) {
      if (typeof value === 'string' && value.trim() !== '') {
        searchParams.append(key, value)
      }
    }

    Turbo.visit(`${baseUrl}?${searchParams.toString()}`)
  }

  submitDebouncedForm() {
    if (this.pendingTimeout) {
      clearTimeout(this.pendingTimeout)
    }
    this.pendingTimeout = setTimeout(() => this.submitForm(), 2000)
  }

  submitForm() {
    if (this.hasFormTarget) {
      this.formTarget.requestSubmit()
    }
  }

  setSelectedValues() {
    this.selectTargets.forEach((selectTarget) => {
      for (let option of selectTarget.options) {
        if (this.selectedValues[selectTarget.id].includes(option.value)) {
          option.selected = true
          option.classList.add('selected')
        }
      }
    })
  }

  get selectedValues() {
    let selectedValues = {}

    this.hiddenTargets.forEach((hiddenTarget) => {
      selectedValues[hiddenTarget.id] = hiddenTarget.value.split(',')
    })

    return selectedValues
  }
}
