import { Controller } from '@hotwired/stimulus'

export class DependantInputs extends Controller {
  static targets = ['first', 'second', 'third']
  declare readonly hasFirstTarget: boolean
  declare readonly firstTarget: HTMLElement
  declare readonly firstTargets: HTMLElement[]
  declare readonly hasSecondTarget: boolean
  declare readonly secondTarget: HTMLElement
  declare readonly secondTargets: HTMLElement[]
  declare readonly hasThirdTarget: boolean
  declare readonly thirdTarget: HTMLInputElement
  declare readonly thirdTargets: HTMLInputElement[]

  connect() {
    if (this.hasFirstTarget && this.hasSecondTarget && !this.firstValue) {
      this.clearSecond()
      this.disableSecond()
    }

    if (this.hasSecondTarget && this.hasThirdTarget && !this.secondValue) {
      this.clearThird()
      this.disableThird()
    }
  }

  getValue(target = this.firstTarget) {
    const input: HTMLInputElement = target.querySelector('input[type="hidden"]')

    return input.value
  }

  get firstValue() {
    return this.getValue(this.firstTarget)
  }

  get secondValue() {
    return this.getValue(this.secondTarget)
  }

  get thirdValue() {
    return this.getValue(this.thirdTarget)
  }

  handleSelectFirst(event: CustomEvent) {
    const value = event?.detail?.value
    this.setAttributeOnSecond(value)
    this.clearSecond()
    this.enableSecond()
    if (this.hasThirdTarget) {
      this.clearThird()
      this.disableThird()
    }
  }

  handleSelectSecond() {
    if (this.hasThirdTarget) {
      this.clearThird()
      this.enableThird()
    }
  }

  handleFirstTyping() {
    this.clearSecond()
    this.disableSecond()
    if (this.hasThirdTarget) {
      this.clearThird()
      this.disableThird()
    }
  }

  handleSecondTyping() {
    if (this.hasThirdTarget) {
      this.clearThird()
      this.disableThird()
    }
  }

  setAttributeOnSecond(value: string) {
    const pattern = this.secondTarget.dataset.dependantInputsUrlPattern
    const urlValue = pattern.replaceAll(/:first_id/g, value)
    this.secondTarget.dataset.autocompleteUrlValue = urlValue
  }

  clearSecond() {
    this.secondTarget.querySelectorAll('input').forEach((el) => (el.value = ''))
  }

  enableSecond() {
    this.secondTarget
      .querySelectorAll('input')
      .forEach((el) => el.removeAttribute('disabled'))
  }

  disableSecond() {
    this.secondTarget
      .querySelectorAll('input')
      .forEach((el) => el.setAttribute('disabled', 'disabled'))
  }

  clearThird() {
    this.thirdTarget.value = ''
  }

  enableThird() {
    this.thirdTarget.removeAttribute('disabled')
  }

  disableThird() {
    this.thirdTarget.setAttribute('disabled', 'disabled')
  }

  absorbEnterKeypressEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault()
      return false
    }
  }
}
