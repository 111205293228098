import { MapboxRetrievedAddress } from '../models'

export class MapboxClient {
  declare readonly TOKEN: string
  declare readonly SESSION_TOKEN: string

  RETRIEVE_BASE_URL = 'https://api.mapbox.com/search/searchbox/v1/retrieve'

  constructor(token: string, sessionToken: string) {
    this.TOKEN = token
    this.SESSION_TOKEN = sessionToken
  }

  async retrieveAddress(id: string): Promise<MapboxRetrievedAddress | null> {
    const params = new URLSearchParams({
      access_token: this.TOKEN,
      session_token: this.SESSION_TOKEN,
    })

    try {
      const response = await fetch(
        `${this.RETRIEVE_BASE_URL}/${id}?${params.toString()}`,
      )
      const data = await response.json()

      return new MapboxRetrievedAddress(data)
    } catch (e) {
      alert(e)
    }
  }
}
