import {
  MapboxRetrievedAddressFeatureContext,
  MapboxRetrievedAddressFeatureCoordinates,
  MapboxRetrievedAddressPayload,
} from '../types'

export class MapboxRetrievedAddress {
  context: MapboxRetrievedAddressFeatureContext
  coordinates: MapboxRetrievedAddressFeatureCoordinates
  mapbox_id: string

  constructor(data: MapboxRetrievedAddressPayload) {
    const properties = data.features[0]?.properties

    this.context = properties.context
    this.coordinates = properties.coordinates
    this.mapbox_id = properties.mapbox_id
  }

  get lineOne(): string {
    return this.context.address.name
  }

  get city(): string {
    return this.context.place.name
  }

  get county(): string {
    return this.context.district?.name || ''
  }

  get stateOrProvince(): string {
    return this.context.region.region_code
  }

  get postalCode(): string {
    return this.context.postcode.name
  }

  get country(): string {
    return this.context.country.country_code
  }

  get latitude(): number {
    return this.coordinates.latitude
  }

  get longitude(): number {
    return this.coordinates.longitude
  }

  get latlong(): string {
    return `${this.latitude},${this.longitude}`
  }
}
